@font-face {
  font-family: AlteHaasGrotesk;
  src: url('../public/fonts/AlteHaasGroteskRegular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: AlteHaasGrotesk !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: AlteHaasGrotesk !important;
}
