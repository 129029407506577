html {
  scroll-behavior: auto !important;
}

button:focus, a:focus {
  outline: none !important;
}

.app-topbar {
  border-bottom: 1px solid rgb(232, 232, 232);
}

.navbar-brand {
  font-weight: bold;
}

a {
  color: #9B41E5;
  text-decoration: none !important;
}

.app-top-menu a, .offcanvas-body ul a {
  color: #666666;
  font-weight: 600;
  font-size: 1rem;
}

.app-top-menu a:hover, .app-top-menu a:focus, .offcanvas-body ul a:hover, .offcanvas-body ul a:focus {
  color: #9B41E5;
}

.btn-app, .btn-connect-wallet {
  width: fit-content;
  border: 0px;
  border-radius: 15px;
  box-shadow: rgb(50 50 50 / 40%) 0px -2px 0px 0px inset;
  padding: 5px 15px;
  color: #ffffff;
  font-weight: bold;
  background-color: #4542B9;
}

.btn-app:hover, .btn-app:focus {
  color: #ffffff;
  background-color: #9B41E5;
}

.btn-toogle-button, .btn-toogle-button:focus {
  box-shadow: none !important;
  border: none;
  background-color: #4542B9;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}

.btn-close {
  box-shadow: none !important;
}

.home-banner-container .btn-app, .btn-connect-wallet {
  padding: 10px 25px;
  border: 2px solid #4542B9;
  color:#4542B9;
  background-color: transparent;
  box-shadow: none;
}

.home-banner-container .btn-app:hover, .home-banner-container .btn-app:focus, .btn-connect-wallet:hover, .btn-connect-wallet:focus {
  border: 2px solid #4542B9;
  color:#ffffff;
  background-color: #4542B9;
}

.home-section .btn-app, .item-section .btn-app {
  padding: 10px 25px;
}

.btn-filter {
  --bs-btn-color: #555555;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #9B41E5;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #9B41E5;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-color: #4542B9;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #4542B9;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #4542B9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4542B9;
  --bs-gradient: none;
  font-weight: 600;
}

.breadcrumb {
  --bs-breadcrumb-divider-color: #000000;
  --bs-breadcrumb-item-active-color: #000000;
  font-weight: 600;
}

.breadcrumb a {
  color: #4542B9;
}

.breadcrumb a:hover, .breadcrumb a:focus {
  color: #9B41E5;
}



/*
.btn-filter {
  --bs-btn-color: #4542B9;
  --bs-btn-border-color: #4542B9;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4542B9;
  --bs-btn-hover-border-color: #4542B9;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4542B9;
  --bs-btn-active-border-color: #4542B9;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #4542B9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4542B9;
  --bs-gradient: none;
}*/

.home-banner {
	background-color: #ffffff;
	text-align: center;
	position: relative;
	top: 0px;
	overflow: hidden;
}
.home-banner-bg {
	background: linear-gradient(315deg, #DEFFEF 0%, #D6F0FF 100%);
	border-radius: 0 0 200% 200%;
	left: -50%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 200%;
	
}
.home-banner-container {
	border-radius: 0 0 200% 200%;
	overflow: hidden;
	width: 100%;
}
.home-banner-container .container {
	margin: 0 auto;
}
@media (max-width: 768px) {
  .home-banner-container .container, .container-md, .container-sm {
    max-width: 100vw;
  }
}

.home-banner-title {
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.home-banner-title h2 {
  color: #9B41E5; /*rgb(237, 75, 158)*/
  font-weight: bold;
  font-size: 3.25rem;
}
.home-banner-title p, .home-section-des p {
  color: #490A6C;
  font-weight: 800;
}
.home-banner-img {
  animation: float 5s ease-in-out infinite;
  object-fit: contain;
}
.home-banner-img img {
  max-width: 80vw;
  max-height: 80vh;
}

@keyframes float {
	0% {
		transform: translatex(0px) translatey(0px);
	}
	50% {
    transform: translatex(-20px) translatey(-20px);
	}
	100% {
    transform: translatex(0px) translatey(0px);
	}
}

.home-section {
  width: 100%;
  margin: 0;
  background-color: white;
  overflow: hidden;
}

.home-section h2 {
  color: #490A6C;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

.home-section h2 .highlight {
  color: #9B41E5;
  font-weight: 800;
  font-size: 3rem;
}

.home-section-box-value {
  font-weight: 600;
}

.home-section-box-title {
  font-weight: 600;
  color: #555555;
}

.home-section-des {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.home-section-content-animation {
  background: linear-gradient(120deg, #FFFAF0 0%, #FFEDED 100%);
  transform: skewY(-3deg);
  transform-origin: top right;
}

.home-section-content-animation .home-section-container .container {
	margin: 0 auto;
  transform: skewY(3deg);
}

.home-section-eth-circle {
  background: url('../public/assets/home-02.webp') no-repeat center;
  width: 320px;
  height: 320px;
  margin: 40px auto;
  display:grid;
  grid-template-columns: 60px;
  grid-template-rows: 60px;
  place-content: center;
  border: 2px dashed #ffffff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.home-section-eth-img {
  padding: 1.25rem;
}

.home-section-eth-img img {
  object-fit: contain;
}

.home-section-eth-icon {
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  transform: rotate(0) translate(160px) rotate(0);
}

.home-section-eth-icon img {
  object-fit: contain;
  border-radius: 50%;
}

.home-section-eth-icon.icon-1 {
  animation: spin 12s 0s linear infinite;
}
.home-section-eth-icon.icon-2 {
  animation: spin 12s -4s linear infinite;
}
.home-section-eth-icon.icon-3 {
  animation: spin 12s -8s linear infinite;
}

@keyframes spin {
  100% {
    transform:rotate(1turn) translate(160px) rotate(-1turn);
  }
}

.home-section-content-goerli {
  
  background: radial-gradient(ellipse, #DCD6FF 0%, #D6F0FF 100%);
}



.home-section .card {
  border-radius: 25px;
  height: fit-content;
}

.home-section .card .card-body p {
  margin: 0;
}


.breadcrumb {
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.page-banner {
  background: linear-gradient(100deg, #DCD6FF 0%, #D6F0FF 100%);
  padding: 1.5rem;
}

.page-banner-title {
  padding: 0 1.5rem 0 1.5rem;
}

.page-banner-title h2 {
  margin: 0;
  color: #9B41E5;
  font-weight: 800;
  font-size: 2rem;
}

.collection-filter-section .form-label {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.collection-filter-section .form-label-title {
  color: #490A6C;
  font-weight: bold;
}

.collection-filter-section .form-label-sort {
  justify-content: end;
  display: flex;
}

.form-select-sort {
  display: inline-flex;
  width: auto;
  font-weight: 600;
  color: #555555;
  border-radius: 25px;
}

.form-select-sort:active, .form-select-sort:focus {
  border-color: #4542B9;
  color: #4542B9;
  outline: 0;
  box-shadow: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.form-select-sort option {
  background-color: #ffffff !important;
  color: #555555;
  font-weight: 600;
}

.collection-filter-section .form-menu {
  overflow: auto hidden;
  white-space: nowrap;
}

.collection-section .card, .item-section .card {
  border-radius: 25px;
  height: fit-content;
  padding: 15px;
}

.collection-section .card-body p, .item-section .card-body p {
  margin: 0;
  text-decoration: none !important;
}

.collection-image {
  border-radius: 10px;
}

.collection-id {
  color: #9B41E5;
  font-weight: bold;
  font-style: italic;
  font-size: .8rem;
  text-align: end;
}

.collection-name, .item-des {
  margin: 0;
  color: #4542B9;
  font-weight: 800;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-des-label {
  color: #490A6C;
  font-weight: bold;
  font-size: 1rem;
}

.item-model-area {
  border-radius: 25px;
  width: 50%;
  aspect-ratio: 1 / 1;
}

.item-loader {
  color: #555555;
}

.home-model-area {
  width: 50%;
  aspect-ratio: 1 / 1;
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 500px;
  }
}

.modal-header {
  background: linear-gradient(100deg, #DCD6FF 0%, #D6F0FF 100%);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom: none;
}

.modal-content {
  border-radius: 25px;
}

.modal-content .modal-title {
  font-weight: 600;
  color: #4542B9;
}

.connect-wallet-modal-body {
  text-align: center;
}

.pagination {
  justify-content: center;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pagination li {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pagination a {
  border: 1px solid #ffffff;
  color: #555555;
  padding: .5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.pagination a:hover, .pagination a:focus {
  border: 1px solid #9B41E5;
  color: #9B41E5 !important;
}

.pagination-active a {
  border: 1px solid #4542B9;
  color: #4542B9 !important;
  padding: .5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.pagination-icon {
  border: none!important;
}

.pagination-icon:hover, .pagination-icon:focus {
  border: none!important;
}

.pagination-icon:hover .arrow, .pagination-icon:focus .arrow {
  border-color: #9B41E5;
}

.table-events {
  text-align: start;
}

.table-events th {
  color: #490A6C;
}

.table-events td  {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #555555;
}

.table-events td p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-events td a {
  color: #555555;
}

.table-events td a:hover, .table-events td a:focus {
  color: #9B41E5;
}

.offcanvas-body ul {
  list-style: none;
  text-align: center;
  font-weight: 600;
}

.events-no, .p-des {
  font-weight: 600;
  text-align: center;
  color: #555555;
}

.Toastify__progress-bar-theme--light {
  background: #4542B9 !important;
}

.arrow {
  border: solid #555555;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

footer {
  font-weight: 600;
}
